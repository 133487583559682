<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <b-row>
      <b-col lg="9">
        <b-card>
          <b-card-text class="text-center">
            <h2>Welcome to Itech CV</h2>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3">
        <Thread/>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BLink,BRow,BCol } from "bootstrap-vue";
import Thread from '@/views/components/dashboard/Thread.vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    Thread
  },
};
</script>

<style></style>
